<template>
  <v-dialog v-model="dialogAddCompra" persistent scrollable max-width="1600px">
    <v-card tile :loading="loading" :disabled="loading">
      <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
        <v-card-title
          class="pt-3 pb-4 px-5 text-subtitle-1 secondary white--text"
        >
          <v-row class="mb-1 d-flex justify-space-between">
            <v-col class="px-2 pb-0" cols="12" md="4">
              <div>
                <DataField
                  dark
                  :readonly="!PCompraEditar"
                  hide_details
                  :data_sync.sync="compra.data_compra"
                  :label="$tc('global.data') + ' ' + $tc('global.compra')"
                  obrigatorio
                  :disabled="desabilitarCompra"
                  hide-details
                />

                <v-text-field
                  v-if="compra.tipo_compra !== 3"
                  v-model="compra.ncontrol"
                  :label="
                    compra.tipo_compra === 1
                      ? $tc('global.controleInterno')
                      : $tc('global.numero') + ' ' + $tc('global.fatura')
                  "
                  outlined
                  :readonly="!PCompraEditar"
                  dark
                  class="mt-2"
                  hide-details
                  dense
                  required
                  :disabled="desabilitarCompra"
                  :rules="formRules"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="compra.invoice"
                  label="Invoice"
                  :readonly="!PCompraEditar"
                  outlined
                  dark
                  class="mt-2"
                  hide-details
                  dense
                  :disabled="desabilitarCompra"
                  required
                  :rules="formRules"
                ></v-text-field>
              </div>

              <v-btn
                v-if="PDespesaVisualizar"
                block
                outlined
                dark
                class="mt-2"
                @click="openDespesas"
              >
                {{ $tc("global.planoDeGastos") }}
              </v-btn>
              <DialogDespesas
                v-if="dialogDespesas && PCompraEditar"
                :dialogDespesas.sync="dialogDespesas"
                :compra_id="compra.id"
                :compra_status="compra.status"
                :moeda_despesa="selectedMoeda"
                :valorDespesas.sync="valorDespesas"
                @calcular-cif="calcularTotais"
              />
            </v-col>

            <!-- Fornecedores -->
            <v-col class="px-2 pb-0" cols="12" md="4">
              <v-combobox
                :readonly="!PCompraEditar"
                v-model="selectedFornecedor"
                :label="$tc('global.fornecedor')"
                :items="fornecedores"
                item-text="nome"
                item-value="id"
                :search-input.sync="descFornecedor"
                dense
                dark
                outlined
                clearable
                hide-details
                :disabled="desabilitarCompra"
                class="mb-2"
                required
                :rules="formRules"
              >
                <template v-slot:no-data>
                  <v-btn
                    small
                    v-if="PCompraEditar"
                    @click="openAddFornecedor"
                    text
                    block
                    class="primary--text"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ $tc("global.add") + " " + $tc("global.fornecedor") }}
                  </v-btn>
                </template>
              </v-combobox>

              <!-- Condiçao Pagamento -->

              <div class="d-flex">
                <v-row>
                  <v-col cols="7">
                    <v-select
                      v-model="selectedCondPago"
                      :items="condicoes_pagamento"
                      clearable
                      :readonly="!PCompraEditar"
                      outlined
                      dark
                      dense
                      item-text="text"
                      item-value="value"
                      :label="$tc('global.formapagamento')"
                      hide-details
                      :disabled="desabilitarCompra"
                      class="mb-2"
                      required
                      :rules="formRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-show="compra.condpago === 2"
                      v-model="numeroParcelasCompra"
                      hide-details
                      :readonly="!PCompraEditar"
                      dense
                      flat
                      outlined
                      :label="'Nº. ' + $tc('global.parcela', 2)"
                      dark
                      :disabled="desabilitarCompra"
                      append-outer-icon="mdi-plus"
                      @click:append-outer="numeroParcelasCompra++"
                      prepend-icon="mdi-minus"
                      @click:prepend="
                        numeroParcelasCompra > 1 ? numeroParcelasCompra-- : ''
                      "
                      class="ml-3 input-center"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>

              <v-combobox
                v-model="selectedDeposito"
                :label="$tc('global.deposito', 2)"
                :items="depositos"
                :readonly="!PCompraEditar"
                item-text="descricao"
                item-value="id"
                dense
                dark
                :disabled="desabilitarCompra"
                hide-details
                outlined
                required
                :rules="formRules"
              ></v-combobox>
            </v-col>

            <v-col class="px-2 pb-0 d-flex align-end" cols="12" md="4">
              <!-- Moeda -->
              <v-combobox
                v-model="selectedMoeda"
                :label="$tc('global.moeda')"
                :readonly="!PCompraEditar"
                :items="moedas"
                item-text="sigla"
                item-value="id"
                dark
                dense
                outlined
                hide-details
                :disabled="
                  compra_items.length || selectedProduto ? true : false
                "
                required
                :rules="formRules"
              ></v-combobox>

              <v-text-field
                v-if="utilizarCambio"
                v-model.number="cambioCompra"
                :readonly="!PCompraEditar"
                label="Câmbio"
                outlined
                dark
                class="pl-4"
                required
                :rules="formRules"
                @blur="calcularTotais"
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
      </v-form>

      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <div>
          <v-tabs vertical background-color="tocs_gray_1" v-model="tab">
            <v-tab class="caption">
              <v-icon class="mr-1">mdi-cart-outline</v-icon>
              {{ $tc("global.produto") }}
            </v-tab>
            <v-tab :disabled="!utilizarCambio" class="caption">
              <v-icon class="mr-1">mdi-cash</v-icon>
              Cambio
            </v-tab>

            <v-tab-item>
              <v-data-table
                :headers="compra_items_original_headers"
                :items="compra_items"
                :items-per-page="-1"
                disable-sort
                fixed-header
                height="calc(550px - 186px)"
                class="data-tables"
                :item-class="row_classes"
                hide-default-footer
              >
                <template v-slot:item.img="{ item }">
                  <div class="my-1">
                    <v-avatar v-if="item.img">
                      <v-img max-width="65px" :src="item.img"></v-img>
                    </v-avatar>
                    <v-avatar v-else>
                      <v-img
                        max-width="65px"
                        src="@/assets/product-placeholder.jpg"
                      ></v-img>
                    </v-avatar>
                  </div>
                </template>
                <template v-slot:item.produto_descricao="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                        item.produto_descricao | excerpt(15)
                      }}</span>
                    </template>
                    <span>{{ item.produto_descricao }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.qtde="{ item, index }">
                  <template
                    v-if="
                      isCompraItemEditing && index === indexCompraItemEditing
                    "
                  >
                    <div class="d-flex align-center justify-center">
                      <v-text-field
                        v-model.number="compraItemEditing.qtde"
                        placeholder="Qtde"
                        outlined
                        dense
                        class="input-low-height input-center"
                        hide-details
                        append-outer-icon="mdi-plus"
                        @click:append-outer="compraItemEditing.qtde++"
                        prepend-icon="mdi-minus"
                        @blur="calcularTotais"
                        @click:prepend="
                          compraItemEditing.qtde > 1
                            ? compraItemEditing.qtde--
                            : ''
                        "
                      ></v-text-field>
                    </div>
                  </template>
                  <template v-else>
                    {{ item.qtde }}
                  </template>
                </template>
                <template v-slot:item.fob_origem="{ item, index }">
                  <div
                    v-show="
                      isCompraItemEditing && index === indexCompraItemEditing
                    "
                  >
                    <div class="d-flex align-center justify-center">
                      <v-text-field
                        v-model="compraItemEditingPreco"
                        placeholder="Preço"
                        hide-details
                        outlined
                        dense
                        class="input-low-height"
                        ref="compraItemEditingPreco"
                        v-currency="vCurrencyOptions2"
                        @keydown.enter="UpdateCompraItem"
                      ></v-text-field>
                    </div>
                  </div>
                  <div
                    v-show="
                      !isCompraItemEditing && index != indexCompraItemEditing
                    "
                  >
                    <span v-if="selectedMoeda.sigla === 'G$'">
                      {{ item.fob_origem | guarani }}
                    </span>
                    <span v-else>{{ item.fob_origem | currency5digits }} </span>
                  </div>
                </template>

                <template v-slot:item.despesa="{ item, index }">
                  <div
                    v-show="
                      !isCompraItemEditing && index != indexCompraItemEditing
                    "
                  >
                    <span v-if="selectedMoeda.sigla === 'G$'">
                      {{ item.despesa | guarani }}
                    </span>
                    <span v-else>{{ item.despesa | currency5digits }} </span>
                  </div>
                </template>
                <template v-slot:item.cif_origem="{ item, index }">
                  <div
                    v-show="
                      !isCompraItemEditing && index != indexCompraItemEditing
                    "
                  >
                    <span v-if="selectedMoeda.sigla === 'G$'">
                      {{ item.cif_origem | guarani }}
                    </span>
                    <span v-else>{{ item.cif_origem | currency5digits }} </span>
                  </div>
                </template>

                <template v-slot:item.subtotal="{ item, index }">
                  <template
                    v-if="
                      isCompraItemEditing && index === indexCompraItemEditing
                    "
                  >
                    <span v-if="selectedMoeda.sigla === 'G$'">
                      {{
                        (compraItemEditing.qtde * compraItemEditing.fob_origem)
                          | guarani
                      }}
                    </span>
                    <span v-else
                      >{{
                        (compraItemEditing.qtde * compraItemEditing.fob_origem)
                          | currency5digits
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <span v-if="selectedMoeda.sigla === 'G$'">
                      {{ (item.qtde * item.fob_origem) | guarani }}
                    </span>
                    <span v-else
                      >{{ (item.qtde * item.fob_origem) | currency5digits }}
                    </span>
                  </template>
                </template>
                <template
                  v-if="mediaDespesas"
                  v-slot:item.subtotal_cif="{ item }"
                >
                  <template>
                    <span v-if="selectedMoeda.sigla === 'G$'">
                      {{ (item.qtde * item.cif_origem) | guarani }}
                    </span>
                    <span v-else
                      >{{ (item.qtde * item.cif_origem) | currency5digits }}
                    </span>
                  </template>
                </template>
                <template v-slot:item.actions="{ item, index }">
                  <template
                    v-if="
                      isCompraItemEditing && index === indexCompraItemEditing
                    "
                  >
                    <v-btn
                      color="green lighten-1"
                      icon
                      small
                      @click.stop="UpdateCompraItem"
                    >
                      <v-icon size="20">mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      icon
                      small
                      @click.stop="StartEditCompraItem(item, index)"
                    >
                      <v-icon size="20">mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                      color="red lighten-2"
                      icon
                      small
                      @click.stop="DeleteCompraItem(index)"
                    >
                      <v-icon size="20">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="compra_items_cambio_headers"
                :items="compra_items"
                :items-per-page="-1"
                :item-class="row_classes"
                disable-sort
                fixed-header
                height="
               calc(550px - 186px)
              "
                class="data-tables"
                hide-default-footer
              >
                <template v-slot:item.img="{ item }">
                  <div class="my-1">
                    <v-avatar v-if="item.img">
                      <v-img max-width="65px" :src="item.img"></v-img>
                    </v-avatar>
                    <v-avatar v-else>
                      <v-img
                        max-width="65px"
                        src="@/assets/product-placeholder.jpg"
                      ></v-img>
                    </v-avatar>
                  </div>
                </template>
                <template v-slot:item.qtde="{ item }">
                  <template>
                    {{ item.qtde }}
                  </template>
                </template>

                <template v-if="utilizarCambio" v-slot:item.fob="{ item }">
                  <div>
                    <span v-if="moeda_base.sigla === 'G$'">
                      {{ item.fob | guarani }}
                    </span>
                    <span v-else>
                      {{ item.fob | currency5digits }}
                    </span>
                  </div>
                </template>

                <template v-slot:item.despesa_cambio="{ item }">
                  <div>
                    <span v-if="moeda_base.sigla === 'G$'">
                      {{ item.despesa_cambio | guarani }}
                    </span>
                    <span v-else
                      >{{ item.despesa_cambio | currency5digits }}
                    </span>
                  </div>
                </template>

                <template v-slot:item.cif="{ item, index }">
                  <div
                    v-show="
                      !isCompraItemEditing && index != indexCompraItemEditing
                    "
                  >
                    <span v-if="moeda_base.sigla === 'G$'">
                      {{ item.cif | guarani }}
                    </span>
                    <span v-else>{{ item.cif | currency5digits }} </span>
                  </div>
                </template>

                <template v-slot:item.subtotal="{ item }">
                  <template>
                    <span v-if="moeda_base.sigla === 'G$'">
                      {{ (item.qtde * item.fob) | guarani }}
                    </span>
                    <span v-else
                      >{{ (item.qtde * item.fob) | currency5digits }}
                    </span>
                  </template>
                </template>

                <template
                  v-if="mediaDespesas"
                  v-slot:item.subtotal_cif="{ item }"
                >
                  <template>
                    <span v-if="moeda_base.sigla === 'G$'">
                      {{ (item.qtde * item.cif) | guarani }}
                    </span>
                    <span v-else
                      >{{ (item.qtde * item.cif) | currency5digits }}
                    </span>
                  </template>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs>

          <DialogAddProduto
            v-if="dialogAddProduto"
            :dialogAddProduto.sync="dialogAddProduto"
            :descProduto="buscaProduto"
            :buscaProduto.sync="buscaProduto"
            :setarProduto.sync="setarProduto"
            @setaProduto="setaProduto"
          />

          <DialogAddFornecedor
            v-if="dialogAddFornecedor"
            :dialogAddFornecedor.sync="dialogAddFornecedor"
            :fornecedorCriado.sync="fornecedorCriado"
            :descFornecedor="descFornecedor"
            @buscaFornecedores="getFornecedores"
          />

          <div
            style="position: relative"
            class="tocs_gray_1 d-flex align-center flex-wrap pt-4 px-4"
          >
            <!-- Busca Produto -->
            <SearchProdutosMultiDeposito
              :produto_sync.sync="selectedProduto"
              :moeda_id="compra.moeda_id"
              :deposito_id="compra.deposito_id"
              @clear-add-item="ClearAddCompraItem"
              ref="buscaProduto"
              typeComp="compra"
              :disableAll="!PCompraEditar"
              style="max-width: 45%"
            />

            <!-- Quantidade -->
            <v-text-field
              v-model.number="compraItemQtde"
              placeholder="Qtde"
              :readonly="!PCompraEditar"
              hide-details
              dense
              solo
              flat
              outlined
              class="mx-3 input-center"
              style="max-width: 15%"
              append-outer-icon="mdi-plus"
              ref="compraItemQtde"
              @click:append-outer="compraItemQtde++"
              prepend-icon="mdi-minus"
              @keydown.enter="focusPreco"
              @blur="focusPreco"
              @click:prepend="compraItemQtde > 1 ? compraItemQtde-- : ''"
            ></v-text-field>

            <!-- Preço -->
            <v-text-field
              v-model="compraItemPreco"
              placeholder="Preço"
              hide-details
              :readonly="!PCompraEditar || disableAddCompraItem"
              dense
              solo
              flat
              outlined
              class="mr-3"
              style="max-width: 15%"
              @keydown.enter="!disableAddCompraItem ? AddCompraItem() : ''"
              ref="compraItemPreco"
              v-currency="vCurrencyOptions2"
            ></v-text-field>

            <v-btn
              depressed
              color="secondary"
              height="38px"
              absolute
              right
              :disabled="disableAddCompraItem || !PCompraEditar ? true : false"
              :loading="loadingAddCompraItem"
              @click="AddCompraItem"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </div>

          <!-- Totais -->
          <div
            class="tocs_gray_1 d-flex justify-space-between align-center"
            style=""
          >
            <div>
              <v-text-field
                v-model="compra.obs"
                :placeholder="$tc('global.observacao')"
                outlined
                solo
                class="white mx-4"
                hide-details
                :disabled="desabilitarCompra"
                dense
              ></v-text-field>
            </div>

            <div class="px-2 d-flex justify-end">
              <!-- SubTotal -->
              <div class="mx-4 d-flex align-center" style="width: 340px">
                <h3 class="text-h6 pb-0 tocs_gray_2--text pr-4">
                  Subtotal Geral:
                </h3>
                <h3 class="text-h5 text-right">
                  <v-sheet
                    rounded
                    outlined
                    height="40px"
                    min-width="175px"
                    class="blue-grey--text d-flex align-center justify-space-between text--lighten-1 pr-4"
                  >
                    <span class="ml-3 subtitle-1">
                      {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                    </span>
                    <span
                      v-if="selectedMoeda ? selectedMoeda.sigla === 'G$' : ''"
                      >{{ subTotalGeral | guarani }}
                    </span>
                    <span v-else>{{ subTotalGeral | currency5digits }} </span>
                  </v-sheet>
                </h3>
              </div>
              <!-- Desconto -->
              <div class="mx-4 d-flex mt-6" style="width: 320px">
                <h3 class="text-h6 tocs_gray_2--text pr-4 mt-2">Desconto:</h3>
                <v-text-field
                  ref="inputDesconto"
                  v-model="compraDesconto"
                  dense
                  :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                  outlined
                  solo
                  :error-messages="erroDesconto"
                  height="40px"
                  style="width: 100px"
                  :disabled="desabilitarCompra"
                  class="remove-underline input-font-size input-right"
                  v-currency="vCurrencyOptions2"
                  @blur="validaDesconto"
                ></v-text-field>
              </div>
              <!-- Total -->
              <div class="mx-4 d-flex align-center">
                <h3 class="text-h6 tocs_gray_2--text pr-4">
                  {{ $tc("global.total") }}:
                </h3>
                <h3 class="text-h5 text-right">
                  <v-sheet
                    rounded
                    outlined
                    height="40px"
                    min-width="175px"
                    class="blue-grey--text d-flex align-center justify-space-between text--lighten-1 pr-4"
                  >
                    <span class="ml-3 subtitle-1">
                      {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                    </span>
                    <span
                      v-if="selectedMoeda ? selectedMoeda.sigla === 'G$' : ''"
                      >{{ totalGeral | guarani }}
                    </span>
                    <span v-else>{{ totalGeral | currency5digits }} </span>
                  </v-sheet>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <!-- Botoes De Ação -->
      <v-card-actions class="tocs_gray_1 py-3 px-5">
        <v-btn
          v-if="validaStatus"
          class="secondary white--text"
          depressed
          small
          outlined
          @click="
            compra.status !== 2
              ? salvarRascunho()
              : $emit('update:dialogAddCompra', false)
          "
        >
          <v-icon left small class="mx-1">mdi-arrow-left</v-icon>
          Voltar
        </v-btn>

        <v-dialog v-model="dialogCancelarCompra" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="allowDelete && PCompraDeletar"
              color="error"
              class="ml-2"
              small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete-outline</v-icon>
              {{ $tc("global.cancelar") + " " + $tc("global.compra") }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="subtitle-1 tocs_gray_1 primary-2">
              {{ $tc("global.cancelarCompra") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogCancelarCompra = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="ml-2"
                small
                dark
                @click="cancelarCompra"
              >
                <v-icon>mdi-delete-outline</v-icon>
                {{ $tc("global.cancelar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>

        <v-btn
          v-if="showSalvarCompra && PCompraEditar"
          depressed
          small
          color="button_2"
          class="white--text"
          @click="salvarCompra()"
          :disabled="invalidDesconto || compra_items.length <= 0"
        >
          <v-icon left small class="mx-1">mdi-content-save</v-icon>
          Salvar
        </v-btn>

        <DialogFaturarCompras
          v-if="dialogFaturarCompras"
          :dialogFaturarCompras.sync="dialogFaturarCompras"
          :compra="compra"
          :compra_items="compra_items"
          @fatura-compra="salvarRascunho"
        />
        <v-btn
          v-if="compra.status === 1"
          depressed
          small
          color="button_1"
          class="white--text"
          @click="faturarCompra"
          :disabled="invalidDesconto || compra_items.length <= 0"
        >
          <v-icon left small class="mx-1">mdi-credit-card-fast-outline</v-icon>
          {{ $tc("global.faturar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchFornecedoresList } from "@/api/fornecedores/fornecedores.js";
import { fetchDepositosList } from "@/api/produtos/produtos_depositos.js";
import { inputRequired } from "@/helpers/utils.js";
import { mapState, mapGetters } from "vuex";
import { format } from "date-fns";
import { getOneProduto } from "@/api/produtos/produtos.js";
import {
  postCompra,
  startCompra,
  putRascunho,
  cancelaCompra,
} from "@/api/compras/compras.js";
import { fetchComprasItems } from "@/api/compras/compras_items.js";
import { fetchTotalDespesas } from "@/api/compras/compras_despesas.js";

export default {
  name: "DialogAddCompra",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    DialogDespesas: () => import("./DialogDespesas.vue"),
    DialogFaturarCompras: () => import("./DialogFaturarCompras.vue"),
    DialogAddProduto: () => import("./DialogAddProduto.vue"),
    DialogAddFornecedor: () => import("./DialogAddFornecedor.vue"),
    SearchProdutosMultiDeposito: () =>
      import("@/components/fields/SearchProdutosMultiDeposito.vue"),
  },

  props: {
    dialogAddCompra: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    tipo_compra: {
      type: Number,
    },
  },

  data() {
    return {
      loading: false,
      compra: {
        data_compra: format(new Date(), "yyyy-MM-dd"),
        desconto: 0,
        status: 0,
        cambio: 0,
      },
      fornecedores: [],
      depositos: [],
      selectedFornecedor: null,
      selectedDeposito: null,
      selectedMoeda: null,
      condicoes_pagamento: [
        {
          text: this.$tc("global.aVista"),
          value: 1,
        },
        {
          text: this.$tc("global.aPrazo"),
          value: 2,
        },
      ],
      formRules: [inputRequired],
      compra_items: [],
      isCompraItemEditing: false,
      compraItemEditingPreco: null,
      compraItemEditing: null,
      compraItemPreco: null,
      selectedProduto: null,
      validForm: true,
      indexCompraItemEditing: null,
      compra_item: {
        qtde: 1,
      },
      loadingAddCompraItem: false,
      compraDesconto: 0,
      invalidDesconto: false,
      erroDesconto: "",
      loadingCompra: false,
      loadingCompraItems: false,
      dialogDespesas: false,
      validaStatus: true,
      moeda_base: null,
      valorDespesas: null,
      mediaDespesas: null,
      dialogCancelarCompra: false,
      dialogFaturarCompras: false,
      selectedCondPago: null,
      compraItemQtde: 1,
      tab: 0,
      cambioCompra: null,
      dialogAddFornecedor: false,
      dialogAddProduto: false,
      setarProduto: false,
      fornecedorCriado: null,
      descFornecedor: "",
      numeroParcelasCompra: 1,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PCompraEditar() {
      return this.getAccess("Compras", "editar");
    },
    PCompraDeletar() {
      return this.getAccess("Compras", "deletar");
    },
    PDespesaVisualizar() {
      return this.getAccess("PlanoDespesas", "visualizar");
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
      user_id: (state) => state.usuario.id,
    }),
    compra_items_original_headers() {
      let headers = [
        {
          text: "",
          divider: true,
          width: "70px",
          value: "img",
        },
        {
          text: this.$tc("global.produto"),
          divider: true,
          value: "produto_descricao",
        },
        {
          text: this.$tc("global.qtd"),
          divider: true,
          width: "160px",
          value: "qtde",
          align: "center",
        },
        {
          text: `Preço ${this.selectedMoeda ? this.selectedMoeda.sigla : ""}`,
          divider: true,
          width: "160px",
          value: "fob_origem",
          align: "end",
        },
        {
          text: `${
            this.selectedMoeda
              ? this.$tc("global.despesa") + " " + this.selectedMoeda.sigla
              : ""
          }`,
          divider: true,
          width: "160px",
          value: "despesa",
          align: "end",
        },
        {
          text: `${
            this.selectedMoeda
              ? this.$tc("global.consolidado") + " " + this.selectedMoeda.sigla
              : ""
          }`,
          divider: true,
          width: "160px",
          value: "cif_origem",
          align: "end",
        },
        {
          text: "SubTotal",
          divider: true,
          width: "160px",
          value: "subtotal",
          align: "end",
        },
        {
          text: "Total com " + this.$tc("global.despesa"),
          divider: true,
          width: "200px",
          value: "subtotal_cif",
          align: "end",
        },
      ];
      if (this.compra && !this.desabilitarCompra) {
        let actions = {
          text: "",
          width: "90px",
          align: "center",
          value: "actions",
        };

        headers.push(actions);
      }

      return headers;
    },

    compra_items_cambio_headers() {
      let headers = [
        {
          text: "",
          divider: true,
          width: "70px",
          value: "img",
        },
        {
          text: this.$tc("global.produto"),
          divider: true,
          value: "produto_descricao",
        },
        {
          text: this.$tc("global.qtd"),
          divider: true,
          width: "160px",
          value: "qtde",
          align: "center",
        },
        {
          text: `Preço ${this.moeda_base ? this.moeda_base.sigla : ""}`,
          divider: true,
          width: "160px",
          value: "fob",
          align: "end",
        },
        {
          text: `${
            this.moeda_base
              ? this.$tc("global.despesa") + " " + this.moeda_base.sigla
              : ""
          }`,
          divider: true,
          width: "160px",
          value: "despesa_cambio",
          align: "end",
        },
        {
          text: `${
            this.moeda_base
              ? this.$tc("global.consolidado") + " " + this.moeda_base.sigla
              : ""
          }`,
          divider: true,
          width: "160px",
          value: "cif",
          align: "end",
        },
        {
          text: "SubTotal",
          divider: true,
          width: "160px",
          value: "subtotal",
          align: "end",
        },
        {
          text: "Total com " + this.$tc("global.despesa"),
          divider: true,
          width: "200px",
          value: "subtotal_cif",
          align: "end",
        },
      ];

      return headers;
    },

    vCurrencyOptions() {
      return {
        precision: this.moeda_base
          ? this.moeda_base.sigla === "G$"
            ? 0
            : 5
          : "",
        distractionFree: false,
      };
    },

    vCurrencyOptions2() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : 5
          : "",
        distractionFree: false,
      };
    },

    disableAddCompraItem() {
      let result = false;
      if (this.selectedProduto == null) {
        result = true;
      }
      if (this.compra.status == 2) {
        result = true;
      }

      return result;
    },

    subTotalGeral() {
      let result = 0;

      if (this.compra_items.length) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.fob_origem * currentValue.qtde;
        };

        result = this.compra_items.reduce(reducer, 0);
      }
      return result;
    },

    subTotalGeralDespesas() {
      let result = 0;

      if (this.compra_items.length && this.mediaDespesas) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.cif_origem * currentValue.qtde;
        };

        result = this.compra_items.reduce(reducer, 0);
      }

      return result;
    },

    totalGeralSemDespesa() {
      let result = 0;

      if (this.compra_items.length && this.compra) {
        result = this.subTotalGeral;
      }

      return result;
    },

    totalGeral() {
      let result = 0;

      if (this.compra_items.length && this.compra) {
        result = this.totalGeralSemDespesa - Number(this.compra.desconto);
      }

      return result;
    },

    utilizarCambio() {
      let result = false;
      if (this.selectedMoeda) {
        if (this.selectedMoeda.id_moeda !== this.moeda_empresa) {
          result = true;
        }
      }
      return result;
    },
    disableProdutos() {
      let result = false;

      if (
        !this.selectedMoeda ||
        this.compra.status === 2 ||
        this.compra.status === -1 ||
        this.compra.status === -2
      ) {
        result = true;
      }

      return result;
    },

    showSalvarCompra() {
      let result = true;
      if (this.compra) {
        if (this.compra.status === 1) {
          result = true;
        }

        if (this.compra.status === 2) {
          result = false;
        }

        if (this.compra.status === -1) {
          result = false;
        }
      }

      return result;
    },
    desabilitarCompra() {
      let result = false;

      if (this.compra.status === 2) {
        result = true;
      }
      if (this.compra.status === -1) {
        result = true;
      }
      if (this.compra.status === -2) {
        result = true;
      }

      return result;
    },

    allowDelete() {
      let result = true;

      if (this.compra.status === 2 || this.compra.status === -1) {
        result = false;
      }

      return result;
    },
  },

  watch: {
    numeroParcelasCompra() {
      if (this.numeroParcelasCompra > 1 && this.compra.condpago === 2) {
        this.compra.numero_parcelas = this.numeroParcelasCompra;
      } else {
        this.compra.numero_parcelas = null;
      }
    },

    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.compra.fornecedor_id = this.selectedFornecedor.id;
      }
    },

    selectedCondPago() {
      if (this.selectedCondPago) {
        this.compra.condpago = this.selectedCondPago;
        if (this.selectedCondPago === 1) {
          this.numeroParcelasCompra = null;
        }
        if (this.selectedCondPago === 2) {
          this.numeroParcelasCompra = 1;
        }
      }
    },

    selectedDeposito() {
      if (this.selectedDeposito) {
        this.compra.deposito_id = this.selectedDeposito.id;
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda) {
        this.compra.moeda_id = this.selectedMoeda.id_moeda;
      }
    },

    cambioCompra() {
      if (this.cambioCompra) {
        this.compra.cambio = Number(this.cambioCompra);
      }
      if (this.cambioCompra === "") {
        this.compra.cambio = null;
      }
    },

    compraItemQtde() {
      if (this.compraItemQtde) {
        this.compra_item.qtde = this.compraItemQtde;
      }
    },

    selectedProduto() {
      if (this.selectedProduto) {
        this.getProduto();
      }
    },

    totalGeralSemDespesa() {
      this.calcularTotais();
    },

    compraDesconto() {
      if (this.compraDesconto) {
        this.calcularTotais();
      }
    },
  },

  methods: {
    row_classes(linha) {
      if (
        this.compra_items.filter((x) => x.produto_id === linha.produto_id)
          .length > 1
      ) {
        return "tocs_red--text";
      }
    },
    async getProduto() {
      let filtro = `?moeda_id=${this.selectedMoeda.id_moeda}`;
      const produto = await getOneProduto(this.selectedProduto.id, filtro);

      this.$ci.setValue(
        this.$refs.compraItemPreco,
        Number(produto.ucif ? produto.ucif : 0)
      );

      this.compra_item.fob_origem = 0;
      this.compra_item.produto_id = produto.id;
      this.compra_item.produto_descricao = produto.descricao;
      this.compra_item.iva_porcentagem = produto.iva_porcentagem;
      this.compra_item.qtde = this.compraItemQtde;
      if (produto.img) {
        this.compra_item.img = produto.img;
      }

      this.$refs.compraItemQtde.focus();
    },

    cambioResolve(moeda_origem, valor_origem, moeda_destino, valor_destino) {
      let cotacao = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        cotacao = valor_origem * valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        cotacao = valor_origem * valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        cotacao = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        cotacao = valor_destino / valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        cotacao = valor_destino / valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        cotacao = valor_destino / valor_origem;
      }

      return cotacao ? cotacao + Number.EPSILON : cotacao;
    },

    calcularTotais() {
      this.mediaDespesas = this.valorDespesas / this.totalGeralSemDespesa;

      if (this.mediaDespesas > 0) {
        this.compra_items.forEach((item) => {
          item.despesa = this.mediaDespesas * Number(item.fob_origem);
          item.despesa_cambio = this.cambioResolve(
            this.moeda_base.sigla,
            Number(this.compra.cambio),
            this.selectedMoeda.sigla,
            item.despesa
          );
          item.cif_origem =
            item.despesa + Number(item.fob_origem) + Number.EPSILON;
          if (this.utilizarCambio) {
            item.cif = this.cambioResolve(
              this.moeda_base.sigla,
              item.cif_origem,
              this.selectedMoeda.sigla,
              Number(this.compra.cambio)
            );
          } else {
            item.cif = item.cif_origem;
          }

          return item;
        });
      } else {
        this.compra_items.forEach((item) => {
          if (this.compra.cambio) {
            item.fob = this.cambioResolve(
              this.moeda_base.sigla,
              Number(item.fob_origem),
              this.selectedMoeda.sigla,
              Number(this.compra.cambio)
            );
          }
          item.cif_origem = Number(item.fob_origem);
          item.despesa = 0;
          item.cif = item.fob;

          return item;
        });
      }
    },
    async getFornecedores() {
      const response = await fetchFornecedoresList();
      this.fornecedores = response;

      if (this.fornecedorCriado) {
        this.selectedFornecedor = this.fornecedores.find((fornecedor) => {
          return fornecedor.nome === this.fornecedorCriado.nome.toUpperCase();
        });
      }
    },

    async getDepositos() {
      const response = await fetchDepositosList();
      this.depositos = response;
    },

    StartEditCompraItem(item, index) {
      this.isCompraItemEditing = true;
      this.compraItemEditing = { ...item };
      this.indexCompraItemEditing = index;

      this.$ci.setValue(this.$refs.compraItemEditingPreco, item.fob_origem);
    },

    UpdateCompraItem() {
      this.compra_items[this.indexCompraItemEditing].fob_origem =
        this.compraItemEditing.fob_origem;

      this.compra_items[this.indexCompraItemEditing].qtde =
        this.compraItemEditing.qtde;

      if (this.utilizarCambio) {
        this.compra_items[this.indexCompraItemEditing].fob_origem =
          this.$ci.parse(this.compraItemEditingPreco, this.vCurrencyOptions2);
      } else {
        this.compra_items[this.indexCompraItemEditing].fob_origem =
          this.$ci.parse(this.compraItemEditingPreco, this.vCurrencyOptions2);
        this.compra_items[this.indexCompraItemEditing].fob = this.$ci.parse(
          this.compraItemEditingPreco,
          this.vCurrencyOptions
        );
      }
      this.compraItemEditing.fob_origem = this.$ci.parse(
        this.compraItemEditingPreco,
        this.vCurrencyOptions2
      );

      this.isCompraItemEditing = false;
      this.compraItemEditing = null;
      this.indexCompraItemEditing = null;
    },

    setaProduto() {},

    DeleteCompraItem(index) {
      this.compra_items.splice(index, 1);
    },

    ClearAddCompraItem() {
      this.selectedProduto = null;
      this.compra_item.produto_id = null;
      this.compra_item.produto_descricao = null;
      this.compraItemQtde = 1;
      this.compra_item.img = null;
      this.compra_item.iva_porcentagem = null;

      this.$ci.setValue(this.$refs.compraItemPreco, 0);
    },

    AddCompraItem() {
      this.loadingAddCompraItem = true;

      if (this.compraItemQtde >= 0 && this.compraItemPreco) {
        let compra_item = { ...this.compra_item };
        if (this.utilizarCambio && this.compra.cambio) {
          compra_item.fob_origem = this.$ci.parse(
            this.compraItemPreco,
            this.vCurrencyOptions2
          );
          compra_item.fob = this.cambioResolve(
            this.moeda_base.sigla,
            this.compra.cambio,
            this.selectedMoeda.sigla,
            this.compraItemPreco
          );
        } else {
          compra_item.fob_origem = this.$ci.parse(
            this.compraItemPreco,
            this.vCurrencyOptions2
          );
          compra_item.fob = this.$ci.parse(
            this.compraItemPreco,
            this.vCurrencyOptions
          );
        }
        this.compra_items.push(compra_item);

        this.$nextTick().then(() => {
          this.ClearAddCompraItem();
          this.$refs.buscaProduto.$refs.buscaProduto.focus();
        });

        this.loadingAddCompraItem = false;
      }
      if (!this.compraItemPreco) {
        this.$toast.error(
          "Favor inserir preço antes de adicionar um item na lista!"
        );
        this.loadingAddCompraItem = false;
      }
      if (!this.compraItemQtde || this.compraItemQtde <= 0) {
        this.$toast.error("Quantidade minima para adicionar o item é 1!");
        this.compraItemQtde = 1;
        this.loadingAddCompraItem = false;
      }
    },

    focusPreco() {
      if (this.compraItemQtde >= 1) {
        this.$nextTick().then(() => {
          this.$refs.compraItemPreco.focus();
        });
      }
    },

    validaDesconto() {
      let desconto = this.$ci.parse(this.compraDesconto, this.vCurrencyOptions);

      if (desconto === null || desconto === undefined || desconto === "") {
        this.invalidDesconto = true;
      } else {
        if (desconto < 0 || desconto > this.subTotalGeral || isNaN(desconto)) {
          this.erroDesconto = "Valor de desconto inválido";
          this.invalidDesconto = true;
        } else {
          this.erroDesconto = "";

          this.compra.desconto = Number(desconto);

          this.invalidDesconto = false;
        }
      }
    },

    salvarCompra() {
      if (this.$refs.form.validate()) {
        this.loadingCompra = true;

        let compra = {};
        compra.moeda_id = this.compra.moeda_id;
        compra.fornecedor_id = this.compra.fornecedor_id;
        compra.deposito_id = this.compra.deposito_id;
        compra.usuario_id = this.user_id;
        compra.data_compra = this.compra.data_compra;
        compra.sub_total = this.subTotalGeral;
        compra.total = this.totalGeral;
        compra.desconto = this.compra.desconto;
        compra.cambio = this.compra.cambio
          ? parseFloat(this.compra.cambio).toFixed(2)
          : null;
        compra.condpago = this.compra.condpago;
        compra.ncontrol = this.compra.ncontrol;
        compra.obs = this.compra.obs;
        compra.compra_items = this.compra_items;
        compra.tipo_compra = this.compra.tipo_compra;
        compra.invoice = this.compra.invoice;
        compra.moeda_base = this.moeda_base.id_moeda;
        if (this.compra.condpago === 2) {
          compra.numero_parcelas = this.numeroParcelasCompra;
        } else {
          compra.numero_parcelas = null;
        }

        postCompra(this.compra.id, compra)
          .then(() => {
            this.loadingCompra = false;
            this.$emit("update:dialogAddCompra", false);
            this.$emit("fetch-compras");
          })
          .finally(() => {
            this.loadingCompra = false;
          });
      }
    },

    salvarRascunho() {
      try {
        this.loadingCompra = true;

        let compra = {};
        compra.moeda_id = this.compra.moeda_id;
        compra.fornecedor_id = this.compra.fornecedor_id;
        compra.deposito_id = this.compra.deposito_id;
        compra.usuario_id = this.user_id;
        compra.data_compra = this.compra.data_compra;
        compra.sub_total = this.subTotalGeral;
        compra.total = this.totalGeral;
        compra.desconto = this.compra.desconto;
        compra.cambio = this.compra.cambio
          ? parseFloat(this.compra.cambio).toFixed(2)
          : null;
        compra.condpago = this.compra.condpago;
        compra.ncontrol = this.compra.ncontrol;
        compra.obs = this.compra.obs;
        compra.compra_items = this.compra_items;
        compra.tipo_compra = this.compra.tipo_compra;
        compra.invoice = this.compra.invoice;
        compra.moeda_base = this.moeda_base.id_moeda;
        if (this.compra.condpago === 2) {
          compra.numero_parcelas = this.numeroParcelasCompra;
        } else {
          compra.numero_parcelas = null;
        }

        putRascunho(this.compra.id, compra)
          .then(() => {
            this.loadingCompra = false;
            this.$emit("update:dialogAddCompra", false);
            this.$emit("fetch-compras");
          })
          .finally(() => {
            this.loadingCompra = false;
          });
      } catch (error) {
        this.loadingCompra = false;
      }
    },

    getCompraItems() {
      this.loadingCompraItems = true;
      return fetchComprasItems(this.compra.id)
        .then((response) => {
          this.compra_items = response;
        })
        .finally(() => (this.loadingCompraItems = false));
    },

    async initEditCompra() {
      this.loading = true;
      this.compra = { ...this.item };

      if (this.compra.tipo_compra === 3) {
        this.compra.invoice = this.compra.documento;
      } else {
        this.compra.ncontrol = this.compra.documento;
      }

      this.cambioCompra = Number(this.compra.cambio);

      this.selectedFornecedor = this.fornecedores.find((item) => {
        return item.id === this.compra.fornecedor_id;
      });
      this.selectedDeposito = this.depositos.find((item) => {
        return item.id === this.compra.deposito_id;
      });
      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda === this.compra.moeda_id;
      });
      this.moeda_base = this.moedas.find((item) => {
        return item.id_moeda === this.moeda_empresa;
      });
      this.selectedCondPago = this.compra.condpago;
      this.$ci.setValue(this.$refs.inputDesconto, this.compra.desconto);
      this.loading = false;
      await this.getCompraItems();
    },

    openDespesas() {
      if (this.PCompraEditar) {
        this.dialogDespesas = true;
      }
    },

    async initiateCompra() {
      this.loading = true;
      this.compra = await startCompra();
      if (this.tipo_compra) {
        this.compra.tipo_compra = this.tipo_compra;
      }
      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda === this.moeda_empresa;
      });

      this.moeda_base = Object.assign(this.selectedMoeda);
      this.loading = false;
    },

    async getTotalDespesas() {
      this.loading = true;
      await fetchTotalDespesas(this.compra.id)
        .then((response) => {
          this.valorDespesas = Number(response[0].total);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    cancelarCompra() {
      this.loading = true;
      if (this.allowDelete) {
        cancelaCompra(this.compra.id)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success(this.$tc("global.compraDescartada"));
              this.dialogCancelarCompra = false;
              this.$emit("update:dialogAddCompra", false);
              this.$emit("fetch-compras");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$toast.error(this.$tc("global.descarteNaoPossivel"));
      }
    },

    faturarCompra() {
      this.dialogFaturarCompras = true;
    },

    openAddProduto() {
      this.dialogAddProduto = true;
    },

    openAddFornecedor() {
      this.dialogAddFornecedor = true;
    },
  },

  async mounted() {
    try {
      // this.row_classes();
      this.loading = true;
      if (!this.moeda_empresa) {
        this.$toast.error(this.$tc("global.addMoedaEmpresa"));
        this.$emit("update:dialogAddCompra", false);
      }

      await this.getFornecedores();
      await this.getDepositos();

      if (!this.edit && this.moeda_empresa) {
        await this.initiateCompra();
        this.numeroParcelasCompra = 1;
      } else {
        await this.initEditCompra();
        await this.getTotalDespesas();
        this.calcularTotais();
        this.numeroParcelasCompra = this.compra.numero_parcelas;
        // console.log(this.numeroParcelasCompra ,this.compra.numero_parcelas);
      }

      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.remove-underline ::v-deep .v-input__slot::before {
  border-style: none !important;
}
.input-right ::v-deep input {
  text-align: right;
}
.input-font-size ::v-deep input {
  font-size: 1.5rem;
}
</style>
